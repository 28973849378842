import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-rotatedmarker";
import "./Map.css";
// import ICON from '../../../assets/orange_car_sm.png';
import ICON from "../../../assets/kayak.png";

let mapView;

const Map = ({ assets, event }) => {
  const [mapCenter, setMapCenter] = useState([35.9135, 14.4213]);

  const customIcon = L.icon({
    iconUrl: ICON,
    iconSize: [64, 64],
    iconAnchor: [32, 32],
  });

  //Set the leaflet map on first render
  useEffect(() => {
    var container = L.DomUtil.get("mapDiv");

    if (container != null) {
      container._leaflet_id = null;
    }

    mapView = L.map("mapDiv").setView(mapCenter, 10);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      mapView
    );
  }, []);

  //Create a new marker
  const createMarker = (asset, latitude, longitude) => {
    const latlng = new L.LatLng(latitude, longitude);

    var marker = new L.marker(latlng, {
      draggable: false,
      rotationAngle: 90,
      rotationOrigin: "center",
      id: asset.M_AssetID,
      icon: customIcon,
    });

    marker.bindPopup(asset.M_Name2);
    mapView.addLayer(marker);
    mapView.setView(latlng, 10);

    setMapCenter([latitude, longitude]);
    return marker;
  };

  //On event message, change marker position and rotation angle
  useEffect(() => {
    if (event && event.speedInKmph > 0) {
      for (const m in mapView._layers) {
        if (
          mapView._layers[m] &&
          mapView._layers[m].options &&
          mapView._layers[m].options.id &&
          mapView._layers[m].options.id.toUpperCase() ===
            event.assetId.toUpperCase()
        ) {
          var newLatLng = new L.LatLng(event.latitude, event.longitude);
          mapView.setView(newLatLng);

          mapView._layers[m].setLatLng(newLatLng);
          mapView._layers[m].setRotationAngle(event.heading + 90);
        }
      }
    }
  }, [event]);

  //On asset list response, create a new marker for each asset
  useEffect(() => {
    if (assets && assets.length > 0 && mapView) {
      //remove all markers and add again
      const points =
        assets && assets.length > 0
          ? assets.map((p) => {
              createMarker(
                p,
                p.M_LastPosition_Latitude,
                p.M_LastPosition_Longitude
              );
              return [p.M_LastPosition_Latitude, p.M_LastPosition_Longitude];
            })
          : [];
    }
  }, [assets]);

  return <div id="mapDiv" />;
};

export default Map;
