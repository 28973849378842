import { Row } from "antd";
import HANDSON_LOGO from "../../../assets/sponsors/handson_logo_white.png";
import RITZ_LOGO from "../../../assets/sponsors/ritz_logo_white.png";
import FLAVOURSFORHEALTH_LOGO from "../../../assets/sponsors/flavoursForHealth_logo_white.png";
import RUBENVASSALLO_LOGO from "../../../assets/sponsors/rubenVassallo_logo_white.png";
import SAILMALTACHARTER_LOGO from "../../../assets/sponsors/sailMaltaCharter_logo_white.png";
import "./Sponsors.css";

const Sponsors = () => {
  return (
    <Row className="sponsors-section">
      <div className="sub-title">Main Sponsors</div>

      <div className="sponsors">
        <div>
          <img src={HANDSON_LOGO} className="logo-lg-img" alt="handson_logo" />
        </div>
        <div>
          <img src={RITZ_LOGO} className="logo-lg-img" alt="ritz_logo" />
          <img
            src={FLAVOURSFORHEALTH_LOGO}
            className="logo-lg-img"
            alt="flavoursForHealth_logo"
          />
          <img
            src={RUBENVASSALLO_LOGO}
            className="logo-lg-img"
            alt="rubenVassallo_logo"
          />
        </div>

        <div>
          <img
            src={SAILMALTACHARTER_LOGO}
            className="logo-lg-img"
            alt="sailMaltaCharter_logo"
            style={{ marginLeft: "-5px" }}
          />
        </div>
      </div>
    </Row>
  );
};

export default Sponsors;
