import "./LeftPanel.css";
import Sponsors from "./Sponsors/Sponsors";
import Text from "./Text/Text";
import Logo from "./Logo/Logo";
import VisitCounter from "./VisitCounter/VisitCounter";

const LeftPanel = () => {
  return (
    <>
      <Logo />
      <Text />
      <Sponsors />
      <VisitCounter />
    </>
  );
};

export default LeftPanel;
