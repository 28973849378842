import { Row } from "antd";
import "./Text.css";

const Text = () => {
  return (
    <Row className="text-section">
      <div className="text">
        Help us make this fundraising event a successful one by donating on:
        <br></br>Revolut: 79520171
        <br></br>BOV Banking: 99477062
      </div>
      <div className="text"></div>
    </Row>
  );
};

export default Text;
